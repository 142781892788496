// 获取验证码按钮

<template>
  <el-button :type="disabled ? '' : 'primary'" :loading="loading" :disabled="disabled" @click="getSmsCode()">
    {{ disabled ? countDown + ' 秒后重试' : '获取验证码' }}
  </el-button>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {
    mobile: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      mobileReg: /^1[3456789]\d{9}$/,
      loading: false,
      countDown: 0,
      disabled: false,
      timer: null
    }
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    // 获取短信验证码
    async getSmsCode() {
      // 验证手机号
      if (!this.mobileReg.test(this.mobile)) {
        this.$message.error('手机号码格式不正确!')
        return
      }

      this.loading = true
      const res = await this.$api.login.getSmsCode({ phoneNumber: this.mobile })
      this.loading = false

      if (res.code === '00000') {
        this.countDown = 60
        this.disabled = true
        this.$message.success('验证码发送成功!')

        this.timer = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--
          } else {
            this.disabled = false
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.component {
}
</style>
